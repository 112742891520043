.scb--slider {
  &__image {
    position: relative;
    padding-bottom: 100%;
    border-radius: 2rem;
    overflow: hidden;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 70%;
    }

    &__img {
      object-fit: cover;
    }
  }

  .swiper {
    padding-bottom: 0 !important;
  }

  .swiper-pagination {
    display: flex !important;
    width: auto !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    background: transparent !important;
    bottom: $bit-padding * 2 !important;
    border-radius: .5rem;

    .swiper-pagination-bullet {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      margin: 0 .5rem !important;
      background-color: rgba($white, .4);
      border: 2px solid $white;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        border-radius: 50%;
        background-color: $gray;
      }
    }
  }
}