//== Default flex classes
//=========================================================

.flex {
    display: flex;

    &--wrap {
        flex-wrap: wrap;
    }

    &--nowrap {
        flex-wrap: nowrap;
    }

    &--row {
        flex-direction: row;
    }

    &--column {
        flex-direction: column;
    }

    &--align-center {
        align-items: center;
    }

    &--align-flex-start {
        align-items: flex-start;
    }

    &--align-flex-end {
        align-items: flex-end;
    }

    &--align-stretch {
        align-items: stretch;
    }

    &--justify-center {
        justify-content: center;
    }

    &--justify-flex-start {
        justify-content: flex-start;
    }

    &--justify-flex-end {
        justify-content: flex-end;
    }

    &--justify-space-between {
        justify-content: space-between;
    }

    &--justify-space-around {
        justify-content: space-around;
    }
}
