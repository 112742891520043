.cb--cta_2 {
  padding-top: 0;

  $this: &;

  &[data-bg-color="green-lightest"] {
    #{$this}__image::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.597'%3E%3Cpath data-name='Subtraction 7' d='M1920 51.601c-75.275-7.263-152.56-13.739-229.708-19.25-77.929-5.566-157.7-10.289-237.112-14.037-80.308-3.79-162.3-6.681-243.7-8.592a10677.135 10677.135 0 0 0-498.957 0c-81.411 1.911-163.4 4.8-243.7 8.592-79.41 3.748-159.186 8.47-237.112 14.037C152.559 37.862 75.274 44.338.003 51.601V.001h1920v51.595Z' fill='%23F1F6ED'/%3E%3C/svg%3E");
    }
  }

  &[data-prev-cb-edge-bottom] {
    padding-top: 0;
    z-index: 0;
    position: relative;

    #{$this}__image-wrapper {
      z-index: 0;
    }
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 50rem;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);

    &::after {
      position: absolute;
      content: " ";
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 5rem;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 110% auto;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.597'%3E%3Cpath data-name='Subtraction 7' d='M1920 51.601c-75.275-7.263-152.56-13.739-229.708-19.25-77.929-5.566-157.7-10.289-237.112-14.037-80.308-3.79-162.3-6.681-243.7-8.592a10677.135 10677.135 0 0 0-498.957 0c-81.411 1.911-163.4 4.8-243.7 8.592-79.41 3.748-159.186 8.47-237.112 14.037C152.559 37.862 75.274 44.338.003 51.601V.001h1920v51.595Z' fill='%23DFEEEB'/%3E%3C/svg%3E");
      z-index: 1;
      transform: rotate(180deg);
    }

    &__img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__box {
    background-color: $white;
    box-shadow: rgba($black, .1) 0 .3rem 1.2rem;
    border-radius: $border-radius-large;
    margin-top: - 15rem;
    position: relative;
    z-index: 2;
  }

  &__title > * {
    font-weight: $font-weight-demi;
    @include responsive-font-size(((2.8rem), ($screen-sm-min, 3.8rem)));
  }
}