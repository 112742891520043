$input-border-radius: 1rem;
$input-border: 1px solid $gray;
$input-height: 6rem;

//== Forms Base Styling
//=========================================================
input[type=text],
input[type=phone],
input[type=email],
input[type=password],
input[type=tel],
input[type=search],
input[type=number],
input[type=time],
input[type=date],
input[type=datetime],
input[type=color],
input[type=url],
textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  min-height: $input-height;
  border-radius: $input-border-radius;
  padding: 0 2rem;
  font-family: $font-family-base;
  font-size: $font-size-small;
  line-height: $line-height-base;
  border: $input-border;
  background-image: none;
  -webkit-appearance: none;
  color: $gray;
  transition: all $transition-timing;
  font-weight: $font-weight-demi;
  //text-align: center;

  &::placeholder {
    color: rgba($gray, .6);
  }

  &:focus {
    border-color: $gray-darker;
    outline: 0;
  }
}

select {
  display: block;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 5rem;
  box-sizing: border-box;
  padding: 1.4rem 2rem;
  font-family: $font-family-base;
  font-size: $font-size-small;
  line-height: $line-height-base;
  color: $gray-dark;
  background-image: none;
  border: $input-border;
  border-radius: $input-border-radius;
  transition: all $transition-timing;
  //text-align: center;

  &:focus {
    border-color: $gray-darker;
    outline: 0;
  }

  // target Internet Explorer 9 and Internet Explorer 10:
  @media screen and (min-width: 0 \0
  ) {
    background: none;
    padding: 4px 6px;
  }
}

label {
  display: block;
  font-weight: $font-weight-demi;
  color: $gray;
  //text-align: center;
  padding-bottom: .5rem;
}

textarea {
  padding: 2rem;
  min-height: 10rem;
}

//== Form Specific Styling
//=========================================================
.form-wrapper {
  .form-title {
    display: none;
  }

  .form-select > div {
    color: $gray;
    border: $input-border;
    border-radius: $input-border-radius;
    font-weight: $font-weight-demi;
  }

  .form-field {
    padding-bottom: $bit-padding;

    &.error {
      input {
        border: 2px solid $color-error;
      }

      label {
        color: $color-error;
      }

      .error-message {
        display: none;
      }
    }
  }

  button[type="submit"] {
    padding: 1.7rem 5rem;
    height: $input-height;

    &[disabled] {
      background-color: lightgray;
    }

    &.loading {
      position: relative;

      &::before {
        content: " ";
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        background-image: url("data:image/svg+xml,%3Csvg data-name='Laag 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72 72'%3E%3Cpath d='M530 446a26 26 0 1 1 26-26h10a36 36 0 1 0-36 36Z' transform='translate(-494 -384)' style='fill:%23fff'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @keyframes spin {
          from {
            transform: rotate(0deg);
          } to {
              transform: rotate(360deg);
            }
        }
      }
    }
  }

  .form-confirmation {
    //text-align: center;
    color: $gray;
    font-weight: $font-weight-demi;
  }

  .form-footer {
    padding-top: $bit-padding;
    text-align: center;
  }
}

.form-with-two-cols {
  .form-fields {
    @media (min-width: $screen-md-min) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 (- $bit-padding);
    }
  }

  .form-field {
    @media (min-width: $screen-md-min) {
      width: 50%;
      padding: ($bit-padding / 2) $bit-padding;
    }
  }
}