.cb--cta_1 {
  color: $gray;

  &[data-font-color="green"] {
    color: $green;
  }

  &[data-font-color="blue"] {
    color: $blue;
  }
}