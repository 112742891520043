.cb--reviews {
  text-align: center;

  &__slider {
    margin-right: - $bit-padding;
    margin-left: - $bit-padding;

    .swiper-slide {
      padding: 0 $bit-padding;
      height: auto;

      & > * {
        height: 100%;
        max-width: 50rem;
        margin: 0 auto;
      }
    }
  }
}