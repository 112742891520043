.template-vacancies {
  &__vacancy {
    border-radius: 3rem;
    background-color: $gray-lightest;
    transition: all $transition-timing;

    &:hover, &:active {
      cursor: pointer;
      background-color: darken($gray-lightest, 5%);
    }

    &__is-filled {
      text-transform: uppercase;
      color: $gray;
      font-weight: $font-weight-bold;
      @include responsive-font-size(((1.4rem), ($screen-sm-min, 1.6rem)));
    }
  }

  &__cta {
    color: $white;
    background-color: $gray;
    box-shadow: rgba($black, .1) 0 .3rem 1.2rem;
    border-radius: 3rem;
    font-weight: $font-weight-normal;

    p:last-child {
      padding-bottom: 0;
    }

    &__title {
      @include responsive-font-size(((3rem), ($screen-sm-min, 3.8rem)));
      font-weight: $font-weight-demi;
      line-height: $line-height-tight;
    }
  }
}