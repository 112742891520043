//== Coloring classes
//=========================================================
@each $name, $color in $color-names {
    .background--#{$name} {
        background-color: $color !important;
    }
}

@each $name, $color in $color-names {
    .text--#{$name} {
        color: $color !important;
    }
}
