//== Helpers
//
@mixin clearfix() {
    *zoom: 1;

    &:before,
    &:after {
        content: ' '; // 1
        display: table; // 2
    }

    &:after {
        clear: both;
    }
}

@mixin fontawesome-font {
    font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin responsive-font-size($font-sizes) {
    @each $val in $font-sizes {
        $breakpoint: nth($val, 1);

        @if length($val) == 1 {
            $size: nth($val, 1);

            font-size: $size;

        } @else {
            $re-size: nth($val, 2);

            @media only screen and (min-width: #{$breakpoint}) {
                font-size: $re-size;
            }
        }
    }
}

@mixin responsive-line-height($line-heights) {
    @each $val in $line-heights {
        $breakpoint: nth($val, 1);

        @if length($val) == 1 {
            $size: nth($val, 1);

            line-height: $size;

        } @else {
            $re-size: nth($val, 2);

            @media only screen and (min-width: #{$breakpoint}) {
                line-height: $re-size;
            }
        }
    }
}

// Placeholder text
//
@mixin responsive-placeholder($color: $gray-light, $font-weight: $font-weight-normal, $font-style: normal, $font-family: $font-family-base, $font-sizes: (($font-size-base-xxs), ($screen-sm-min, $font-size-base))) {
    &:-moz-placeholder {
        font-family: $font-family;
        font-weight: $font-weight;
        font-style: $font-style;
        color: $color;
        opacity: 1;
    }

    &::-moz-placeholder {
        font-family: $font-family;
        font-weight: $font-weight;
        font-style: $font-style;
        color: $color;
    }

    &:-ms-input-placeholder {
        font-family: $font-family;
        font-weight: $font-weight;
        font-style: $font-style;
        color: $color;
    }

    &::-webkit-input-placeholder {
        font-family: $font-family;
        font-weight: $font-weight;
        font-style: $font-style;
        color: $color;
    }

    @each $val in $font-sizes {
        $breakpoint: nth($val, 1);

        @if length($val) == 1 {
            $size: nth($val, 1);

            &:-moz-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &::-webkit-input-placeholder {
                font-size: $size;
            }
        } @else {
            $re-size: nth($val, 2);

            @media (min-width: #{$breakpoint}) {
                &:-moz-placeholder,
                &::-moz-placeholder,
                &:-ms-input-placeholder,
                &::-webkit-input-placeholder {
                    font-size: $re-size;
                }
            }
        }
    }
}

// Placeholder text
//
@mixin placeholder($color: $gray-light, $font-size: $font-size-base, $font-weight: $font-weight-normal, $font-style: normal, $font-family: $font-family-base) {
    &:-moz-placeholder {
        font: {
            family: $font-family;
            size: $font-size;
            weight: $font-weight;
            style: $font-style;
        }
        color: $color;
        opacity: 1;
    }

    &::-moz-placeholder {
        font: {
            family: $font-family;
            weight: $font-weight;
            style: $font-style;
            size: $font-size;
        }
        color: $color;
    }

    &:-ms-input-placeholder {
        font: {
            family: $font-family;
            weight: $font-weight;
            style: $font-style;
            size: $font-size;
        }
        color: $color;
    }

    &::-webkit-input-placeholder {
        font: {
            family: $font-family;
            weight: $font-weight;
            style: $font-style;
            size: $font-size;
        }
        color: $color;
    }
}

@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin reset-vertical-align {
    position: inherit;
    top: auto;
    transform: translateY(0);
}

// Vendor Prefixes

// - Animations
// - Backface visibility
// - Box shadow
// - Box sizing
// - Content columns
// - Hyphens
// - Placeholder text
// - Transformations
// - Transitions
// - User Select

// Animations
@mixin animation($animation) {
    -webkit-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}

@mixin animation-name($name) {
    -webkit-animation-name: $name;
    animation-name: $name;
}

@mixin animation-duration($duration) {
    -webkit-animation-duration: $duration;
    animation-duration: $duration;
}

@mixin animation-timing-function($timing-function) {
    -webkit-animation-timing-function: $timing-function;
    animation-timing-function: $timing-function;
}

@mixin animation-delay($delay) {
    -webkit-animation-delay: $delay;
    animation-delay: $delay;
}

@mixin animation-iteration-count($iteration-count) {
    -webkit-animation-iteration-count: $iteration-count;
    animation-iteration-count: $iteration-count;
}

@mixin animation-direction($direction) {
    -webkit-animation-direction: $direction;
    animation-direction: $direction;
}

@mixin animation-fill-mode($fill-mode) {
    -webkit-animation-fill-mode: $fill-mode;
    animation-fill-mode: $fill-mode;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`

@mixin backface-visibility($visibility) {
    -webkit-backface-visibility: $visibility;
    -moz-backface-visibility: $visibility;
    backface-visibility: $visibility;
}

// Border Radius
@mixin border-radius($radius) {
    @if ($radius != 0) {
        @if ($radius == "none") {
            $radius: 0;
        }
        -webkit-border-radius: $radius;
        -moz-border-radius: $radius;
        -ms-border-radius: $radius;
        border-radius: $radius;
    }
}

@mixin border-top-radius($radius) {
    @if ($radius != 0) {
        @if ($radius == "none") {
            $radius: 0;
        }
        border-top-right-radius: $radius;
        border-top-left-radius: $radius;
    }
}

@mixin border-bottom-radius($radius) {
    @if ($radius != 0) {
        @if ($radius == "none") {
            $radius: 0;
        }
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;
    }
}

@mixin border-left-radius($radius) {
    @if ($radius != 0) {
        @if ($radius == "none") {
            $radius: 0;
        }
        -webkit-border-top-left-radius: $radius;
        -moz-border-top-left-radius: $radius;
        -ms-border-top-left-radius: $radius;
        border-top-left-radius: $radius;
        -webkit-border-bottom-left-radius: $radius;
        -moz-border-bottom-left-radius: $radius;
        -ms-border-bottom-left-radius: $radius;
        border-bottom-left-radius: $radius;
    }
}

@mixin border-right-radius($radius) {
    @if ($radius != 0) {
        @if ($radius == "none") {
            $radius: 0;
        }
        -webkit-border-top-right-radius: $radius;
        -moz-border-top-right-radius: $radius;
        -ms-border-top-right-radius: $radius;
        border-top-right-radius: $radius;
        -webkit-border-bottom-right-radius: $radius;
        -moz-border-bottom-right-radius: $radius;
        -ms-border-bottom-right-radius: $radius;
        border-bottom-right-radius: $radius;
    }
}

// Box decoration break
//
// Makes you able to set the background/border flow of a multi line text.
// Reference: https://developer.mozilla.org/en-US/docs/Web/CSS/box-decoration-break
// Example: http://jsbin.com/xojoro/edit?css,output
@mixin box-decoration-break($type) {
    -webkit-box-decoration-break: $type;
    -ms-box-decoration-break: $type;
    -o-box-decoration-break: $type;
    box-decoration-break: $type;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
    box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($boxmodel) {
    -webkit-box-sizing: $boxmodel;
    -moz-box-sizing: $boxmodel;
    box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
    -webkit-column-count: $column-count;
    -moz-column-count: $column-count;
    column-count: $column-count;
    -webkit-column-gap: $column-gap;
    -moz-column-gap: $column-gap;
    column-gap: $column-gap;
}

// Optional font-smoothing
@mixin font-smoothing($mode) {
    -webkit-font-smoothing: $mode;
    -moz-font-smoothing: $mode;
    font-smoothing: $mode;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
    word-wrap: break-word;
    -webkit-hyphens: $mode;
    -moz-hyphens: $mode;
    -ms-hyphens: $mode; // IE10+
    -o-hyphens: $mode;
    hyphens: $mode;
}

// Radial gradient
//
// Using background to be able to set a fallback color/image.
// Use background-color/background-image before implementing the mixin to set the fallback.
@mixin radial-gradient($position, $type-and-size, $from, $to) {
    background: -webkit-radial-gradient($position, $type-and-size, $from, $to);
    background: -moz-radial-gradient($position, $type-and-size, $from, $to);
    background: -ms-radial-gradient($position, $type-and-size, $from, $to);
    background: -o-radial-gradient($position, $type-and-size, $from, $to);
    background: radial-gradient($position, $type-and-size, $from, $to);
}

@mixin text-size-adjust($percentage: 100%) {
    -webkit-text-size-adjust: $percentage;
    -ms-text-size-adjust: $percentage;
    text-size-adjust: $percentage;
}

// Transformations
@mixin scale($ratio...) {
    -webkit-transform: scale($ratio);
    -ms-transform: scale($ratio); // IE9 only
    -o-transform: scale($ratio);
    transform: scale($ratio);
}

@mixin scaleX($ratio) {
    -webkit-transform: scaleX($ratio);
    -ms-transform: scaleX($ratio); // IE9 only
    -o-transform: scaleX($ratio);
    transform: scaleX($ratio);
}

@mixin scaleY($ratio) {
    -webkit-transform: scaleY($ratio);
    -ms-transform: scaleY($ratio); // IE9 only
    -o-transform: scaleY($ratio);
    transform: scaleY($ratio);
}

@mixin skew($x, $y) {
    -webkit-transform: skewX($x) skewY($y);
    -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
    -o-transform: skewX($x) skewY($y);
    transform: skewX($x) skewY($y);
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -ms-transform: translate($x, $y); // IE9 only
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin translateX($x) {
    -webkit-transform: translateX($x);
    -ms-transform: translateX($x); // IE9 only
    -o-transform: translateX($x);
    transform: translateX($x);
}

@mixin translateY($y) {
    -webkit-transform: translateY($y);
    -ms-transform: translateY($y); // IE9 only
    -o-transform: translateY($y);
    transform: translateY($y);
}

@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -ms-transform: rotate($degrees); // IE9 only
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

@mixin rotateX($degrees) {
    -webkit-transform: rotateX($degrees);
    -ms-transform: rotateX($degrees); // IE9 only
    -o-transform: rotateX($degrees);
    transform: rotateX($degrees);
}

@mixin rotateY($degrees) {
    -webkit-transform: rotateY($degrees);
    -ms-transform: rotateY($degrees); // IE9 only
    -o-transform: rotateY($degrees);
    transform: rotateY($degrees);
}

@mixin perspective($perspective) {
    -webkit-perspective: $perspective;
    -moz-perspective: $perspective;
    perspective: $perspective;
}

@mixin perspective-origin($perspective) {
    -webkit-perspective-origin: $perspective;
    -moz-perspective-origin: $perspective;
    perspective-origin: $perspective;
}

@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin; // IE9 only
    transform-origin: $origin;
}

// Transitions

@mixin transition($transition...) {
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin transition-property($transition-property...) {
    -webkit-transition-property: $transition-property;
    transition-property: $transition-property;
}

@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration...) {
    -webkit-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}

@mixin transition-timing-function($timing-function) {
    -webkit-transition-timing-function: $timing-function;
    transition-timing-function: $timing-function;
}

@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
    -moz-transition: -moz-transform $transition;
    -o-transition: -o-transform $transition;
    transition: transform $transition;
}

// User select
// For selecting text on the page

@mixin user-select($select) {
    -webkit-user-select: $select;
    -moz-user-select: $select;
    -ms-user-select: $select; // IE10+
    user-select: $select;
}

@mixin box-decoration-break($s) {
    -webkit-box-decoration-break: $s;
    -o-box-decoration-break: $s;
    box-decoration-break: $s;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}