.scb {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: $screen-sm-min) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media (min-width: $screen-md-min) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &:first-of-type {
    padding-top: 4rem;

    @media (min-width: $screen-sm-min) {
      padding-top: 6rem;
    }

    @media (min-width: $screen-md-min) {
      padding-top: 8rem;
    }
  }

  &:last-of-type {
    padding-bottom: 4rem;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 6rem;
    }

    @media (min-width: $screen-md-min) {
      padding-bottom: 10rem;
    }

    @media (min-width: $screen-lg-min) {
      padding-bottom: 13rem;
    }
  }
}