//== Tailwind
//=========================================================
@tailwind base;
@tailwind components;
@tailwind utilities;

//== Config
//=========================================================
@import './mixins/base-mixins';
@import './mixins/font-helpers';
@import './mixins/decimals';
@import './mixins/strip-number';
@import './mixins/font-face';
@import './mixins/block-edge';
@import './variables';

//== Libs
//=========================================================
@import './lib/base-theme-sass/base-theme-sass';
@import './lib/soda/scss/soda';
@import './lib/fontawesome/fa-regular';
@import './lib/fontawesome/fa-brands';
//@import 'lib/fontawesome/fa-light';
@import './lib/fontawesome/fa-solid';
@import './lib/fontawesome/fontawesome';
//@import '../vendor/slick-carousel/slick/slick';
//@import '../vendor/slick-carousel/slick/slick-theme';
//@import '../vendor/fancybox/dist/jquery.fancybox.css';
//@import '../vendor/aos/dist/aos';

//== Globals
//=========================================================
@import './globals/base';
@import './globals/forms/base';
@import './globals/forms/radio';
@import './globals/forms/consent';
@import './globals/forms/date';
@import './globals/forms/form-field';

//== Overrides
//=========================================================
@import './overrides/swiper';
@import './overrides/fancybox';
@import './overrides/recaptcha';

//== Page Templates
//=========================================================
@import "page-templates/template-projects";
@import "page-templates/template-vacancies";

//== Components
//=========================================================
@import 'components/buttons';
@import 'components/navbar-desktop';
@import 'components/navbar-mobile';
@import 'components/mobile-menu';
@import 'components/main-footer';
@import 'components/pre-footer';
@import 'components/post-footer';
@import 'components/breadcrumbs';
@import 'components/two-images-and-logo';
@import 'components/progress-bar';
@import 'components/image-with-logo';
@import 'components/accordion';
@import 'components/review';
@import 'components/title-banner';
@import 'components/quote';
@import 'components/featured-image';
@import 'components/logo-slider';
@import 'components/tease-post';
@import 'components/content';
@import 'components/quicklinks';
@import 'components/rounded-edge-shape';
@import 'components/modal';
@import 'components/energy-prices-chart';
@import 'components/navbar-topbar';
@import 'components/google-review';
@import 'components/energy-zero-dynamic-tariffs';
@import 'components/energy-zero-address-checker';

//== Content blocks
//=========================================================
@import './content-blocks/cb';
@import './content-blocks/cb-banner_1';
@import './content-blocks/cb-cta_1';
@import './content-blocks/cb-impact';
@import './content-blocks/cb-steps_1';
@import './content-blocks/cb-contact';
@import './content-blocks/cb-banner_2';
@import './content-blocks/cb-usps';
@import './content-blocks/cb-steps_2';
@import './content-blocks/cb-image_and_text';
@import './content-blocks/cb-quote';
@import './content-blocks/cb-cta_2';
@import './content-blocks/cb-faq';
@import './content-blocks/cb-reviews';
@import './content-blocks/cb-savings_example';
@import './content-blocks/cb-reviews_slider';
@import './content-blocks/cb-banner_3';
@import './content-blocks/cb-video';
@import './content-blocks/cb-timeline';
@import './content-blocks/cb-team';
@import './content-blocks/cb-banner_4';
@import './content-blocks/cb-form';
@import './content-blocks/cb-offer';
@import './content-blocks/cb-image';
@import './content-blocks/cb-banner_5';
@import './content-blocks/cb--google_reviews';
@import './content-blocks/cb-banner_6';
@import './content-blocks/cb-link_cards';
@import './content-blocks/cb-energy_zero_form';
@import './content-blocks/cb-banner_7';
@import './content-blocks/cb-image_and_text_3';
@import './content-blocks/cb-two_boxes_with_text_and_image';
@import './content-blocks/cb-banner_8';
@import './content-blocks/cb-video_2';
@import './content-blocks/cb-videos_slider';
@import './content-blocks/cb-banner_9';
@import './content-blocks/cb-cards';
@import './content-blocks/cb-banner_10';
@import './content-blocks/cb-products_2';

@import './simple-content-blocks/scb';
@import './simple-content-blocks/scb-content';
@import './simple-content-blocks/scb-slider';
@import './simple-content-blocks/scb-gallery';
@import './simple-content-blocks/scb-columns';
@import './simple-content-blocks/scb-cta_3';

//== Helpers
//=========================================================
@import './helpers/colors';
@import './helpers/helpers';
@import './helpers/responsive-images';
@import './helpers/text';
@import './helpers/border-radius';
@import './helpers/spacers';
