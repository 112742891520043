.review {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  box-shadow: rgba($black, .1) .3rem .3rem .6rem;
  border-radius: $border-radius-large;
  text-align: center;

  &__rating {
    display: block;
    width: 9rem;
    height: 9.1rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='91.366' height='93.448'%3E%3Cpath data-name='Union 9' d='M22.45 90.418A70.807 70.807 0 0 1 1 80.986s6.476-1.342 13.622-3.179a44.677 44.677 0 1 1 51.664 7.536 52.962 52.962 0 0 1-20.561 6.7 64.515 64.515 0 0 1-23.275-1.625Z' fill='%235ba99b' stroke='%235ba99b' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: $white;
    font-size: 4.1rem;
    text-align: center;
    line-height: 9rem;
    margin: 0 auto 2.5rem auto;
  }

  &__title {
    color: $gray;
    font-weight: $font-weight-bold;
    font-size: 2.1rem;
    padding-bottom: 1rem;
    line-height: $line-height-tight;
  }

  &__text {
    font-style: italic;
  }
}