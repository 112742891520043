@mixin order-classes($namespace) {

	@for $i from 1 through $soda-columns {

		@if ($i != $soda-columns) {
			.#{$soda-class-order}-#{$namespace}-#{$i} {
				order: #{$i};
			}
		}
	}

	.#{$soda-class-off}-#{$namespace}-reset {
		margin-left: 0;
	}
}

@each $val in $soda-breakpoints {
	@if length($val) == 1 {
		$namespace: nth($val, 1);
		@include order-classes($namespace);
	} @else {

		$namespace: nth($val, 1);
		$size: nth($val, 2);

		@media only screen and (min-width: #{$size}) {
			@include order-classes($namespace);
		}
	}
}