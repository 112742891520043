@mixin h1 {
    @include responsive-font-size((($font-size-h1-xxs), ($screen-md-min, $font-size-h1)));
    padding-bottom: $bit-padding / 2;
}

@mixin h2 {
    @include responsive-font-size((($font-size-h2-xxs), ($screen-sm-min, $font-size-h2)));
    padding-bottom: $bit-padding / 2;
}

@mixin h3 {
    @include responsive-font-size((($font-size-h3-xxs), ($screen-sm-min, $font-size-h3)));
    padding-bottom: $bit-padding / 2;
}

@mixin h4 {
    @include responsive-font-size((($font-size-h4-xxs), ($screen-sm-min, $font-size-h4)));
    padding-bottom: $bit-padding / 3;
}

@mixin h5 {
    @include responsive-font-size((($font-size-h5-xxs), ($screen-sm-min, $font-size-h5)));
    padding-bottom: $bit-padding / 3;
    font-family: $font-family-base;
    font-style: normal;
}

@mixin h6 {
    @include responsive-font-size((($font-size-h6-xxs), ($screen-sm-min, $font-size-h6)));
    font-family: $font-family-base;
    font-style: normal;
}
