.cb--form {
  &__title > * {
    @extend .h4;
    text-align: center;
  }

  &__form {
    position: relative;
    padding-bottom: 40%;
    background-color: $gray-lightest;
    color: $gray;
    font-weight: $font-weight-demi;

    p {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-bottom: 0;
      text-align: center;
    }
  }

  &__image {
    width: 40rem;
    max-width: 50%;
    margin: 0 auto -15rem auto;
    transform: translateY(-18rem);
    position: relative;
    z-index: 1;

    @media (min-width: $screen-sm-min) {
      transform: translateY(-22rem);
    }
  }
}