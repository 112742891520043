.cb--video {
  position: relative;
  background-color: gray;

  &::before {
    position: absolute;
    content: " ";
    top: -1px;
    left: 0;
    width: 100%;
    padding-bottom: 8.125%;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 110%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='156'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 442' transform='translate(-8114 -13830)' fill='%23dfeeeb' d='M0 0h1920v156H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 80' transform='translate(8114 13830)' clip-path='url(%23a)'%3E%3Cpath data-name='Path 309' d='M-8020.575-13690.297c1627.695 57.026 2960.169-266.823 2976.163-723.337-4.042-4.542-5896.222-195.164-5894.407-206.51-15.994 456.514 1290.548 872.82 2918.244 929.847Z' fill='%236ea24d' opacity='.35'/%3E%3Cpath data-name='Path 310' d='M-7634.246-13775.808c1622.806-141.75 2906.123-625.63 2866.367-1080.772-4.566-4.017-5877.125 524.811-5876.706 513.326 39.756 455.144 1387.53 709.198 3010.339 567.446Z' fill='%235ba99b' opacity='.35'/%3E%3Cpath data-name='Path 311' d='M-6646.999-13714.801c-1629.325 0-2950.153-370.448-2950.153-827.419 4.2-4.395 5901.723 11.405 5900.306 0 0 456.971-1320.829 827.419-2950.153 827.419Z' fill='%23dfeeeb' opacity='.999'/%3E%3C/g%3E%3C/svg%3E");
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: " ";
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 5rem;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 110%;
    transform: rotate(180deg);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.597'%3E%3Cpath data-name='Subtraction 7' d='M1920 51.601c-75.275-7.263-152.56-13.739-229.708-19.25-77.929-5.566-157.7-10.289-237.112-14.037-80.308-3.79-162.3-6.681-243.7-8.592a10677.135 10677.135 0 0 0-498.957 0c-81.411 1.911-163.4 4.8-243.7 8.592-79.41 3.748-159.186 8.47-237.112 14.037C152.559 37.862 75.274 44.338.003 51.601V.001h1920v51.595Z' fill='%23DFEEEB'/%3E%3C/svg%3E");
  }

  &__placeholder {
    height: 30rem;

    @media (min-width: $screen-md-min) {
      height: 50rem;
    }

    @media (min-width: $screen-lg-min) {
      height: 60rem;
    }

    &__img {
      object-fit: cover;
    }
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7.5rem;
    height: 7.5rem;
    border: .5rem solid $white;
    border-radius: 50%;
    padding: 0;
    background-color: rgba($white, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;

    @media (min-width: $screen-md-min) {
      top: 55%;
      width: 10rem;
      height: 10rem;
      font-size: 4rem;
    }

    i.fas {
      margin: 0;
      padding-left: .5rem;
      color: $white;
      transition: all $transition-timing;
    }

    &:hover, &:active {
      background-color: $white;

      i.fas {
        color: $gray;
      }
    }
  }
}