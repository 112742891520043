.breadcrumbs {
  text-transform: lowercase;
  font-weight: $font-weight-demi;
  font-style: italic;
  font-size: 1.4rem;

  &, a, i.fas {
    color: inherit !important;
    text-decoration: none;
  }

  i {
    margin: 0 .5rem 0 .7rem;
    font-size: 60%;
  }

  a {
    &:hover, &:active {
      text-decoration: underline;
    }
  }
}