.swiper {
  padding-bottom: 3rem !important;

  .swiper-pagination {
    display: flex !important;
    width: auto !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    background: rgba($gray, .2) !important;
    bottom: 0 !important;
    border-radius: .5rem;

    .swiper-pagination-bullet {
      width: 6.4rem;
      height: 1rem;
      border-radius: 0;
      margin: 0 !important;
      background-color: transparent;

      &.swiper-pagination-bullet-active {
        border-radius: .5rem;
        background-color: $gray;
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    text-align: center;
    border-radius: 50%;
    border: 2px solid $white;
    color: $white;
    background-color: rgba($white, .4);
    z-index: 2;
    transition: all $transition-timing;
    transform: translateY(50%);

    &::after {
      content: " ";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='11.5'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 55' fill='%23fff' d='M0 0h13v11.5H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Group 23' clip-path='url(%23a)'%3E%3Cpath data-name='Path 226' d='M0 6.784V4.713a.624.624 0 0 1 .621-.621h6.008V.621A.623.623 0 0 1 7.69.181l5.127 5.127a.623.623 0 0 1 0 .88L7.69 11.316a.621.621 0 0 1-1.061-.44v-3.47H.621A.624.624 0 0 1 0 6.784' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 1.3rem;
      height: 1.1rem;
    }

    &:hover, &:active {
      background-color: rgba($white, .6);
    }
  }

  .swiper-button-prev {
    left: $bit-padding;

    &::after {
      transform: rotate(180deg);
    }
  }

  .swiper-button-next {
    right: $bit-padding;
  }

}