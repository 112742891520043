//== Visually hidden class
// source: https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
//=========================================================
.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

/* Text meant only for screen readers. */
.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}

.display {
    &--block {
        display: block;
    }

    &--inline-block {
        display: inline-block;
    }
}

.position {
    &--relative {
        position: relative;
    }
}