table {
    border-collapse: collapse;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 100%;

    thead {
        border-bottom: 3px solid $table-border-color;

        th, td {
            font-weight: $font-weight-normal;
            padding: 1rem .5rem;
        }
    }

    tbody {
        border-bottom: 3px solid $table-border-color;

        tr {
            border-bottom: 1px solid $table-border-color;

            td, th {
                padding: 1rem .5rem;
            }
        }
    }

}
