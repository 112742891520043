.energy-zero-address-checker {
  &__message-wrapper {
    height: 4.5rem;
    width: 100%;
    font-size: 1.6rem;
    font-weight: bold;
    padding-top: 1.5rem;
    line-height: 1.2;
  }

  &__error {
    color: red;
  }

  &__streetname, &__streetname i.fas {
    color: $green !important;
  }
}