.cb--savings_example {
  &__box {
    background-color: $white;
    box-shadow: rgba($black, .1) .3rem .3rem .6rem;
    border-radius: 2rem;
  }

  hr {
    border-color: $gray;
  }
}