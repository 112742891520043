.quote {
  position: relative;

  &__text {
    color: $gray;
    font-style: italic;
    font-weight: $font-weight-demi;
    @include responsive-font-size(((2.2rem), ($screen-sm-min, 3.2rem)));
    text-align: center;
    position: relative;
    z-index: 2;
    padding: 0 1rem;
    line-height: $line-height-tight;
  }

  &__shape {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 60%;
    background-color: rgba($gray-lightest, .5);
    border-radius: 2rem;
    content: " ";
    z-index: 1;
  }
}