.consent {
  .checkboxes {
    display: flex;

    label {
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
      line-height: 1;
      padding-left: .5rem;
      padding-bottom: 0;
    }
  }
}