ul {
    margin-left: 2.4rem;
    list-style-type: disc;
}

ol {
    margin-left: 2.4rem;
    list-style-type: decimal;
}

strong, b {
    font-weight: $font-weight-normal;
}

em, i {
    font-style: italic;
}

p {
    padding-top: $line-height-base-computed / 2;
    padding-bottom: $line-height-base-computed / 2;
}

h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
    padding-top: 0;
}

hr {
    border: 0;
    border-top: .1rem solid $black;
}

pre {
    display: block;
    overflow: auto;
    font-family: monospace;
    padding: 1rem;
    margin: 0 0 1rem;
    font-size: 1.4rem;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: .1rem solid #ccc;
    border-radius: .4rem;
}

code {
    display: inline-block;
    vertical-align: 15%;
    font-family: monospace;
    padding: .3rem .6rem;
    margin: .1rem 0;
    font-size: 1.4rem;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: .1rem solid #ccc;
    border-radius: .4rem;
}

sub {
    vertical-align: sub;
    font-size: small;
}

sup {
    vertical-align: super;
    font-size: small;
}
