/**
 * You still need to provide the background-image on the ::before or ::after tag when using this mixin!
 */

@mixin block-edge($position:'top') {
  position: relative;

  @if ($position == 'top') {
    &::before {
      position: absolute;
      top: -5rem;
      left: 0;
      content: " ";
      height: 5rem;
      width: 100%;
      background-repeat: no-repeat;
      background-position: bottom;
      z-index: 1;

      @media (min-width: 1920px) {
        height: unset;
        padding-bottom: 2.6%;
        background-size: 100% 100%;
      }
    }
  }

  @if ($position == 'bottom') {
    &::after {
      position: absolute;
      bottom: -5rem;
      left: 0;
      content: " ";
      height: 5rem;
      width: 100%;
      background-repeat: no-repeat;
      background-position: bottom;
      z-index: 1;

      @media (min-width: 1920px) {
        height: unset;
        padding-bottom: 2.6%;
        background-size: 100% 100%;
      }
    }
  }
}