.cb--banner_7 {
  position: relative;

  &[data-next-cb="cards"] {
    padding-bottom: 10rem !important;

    @media (min-width: $screen-lg-min) {
      padding-bottom: 20rem !important;
    }
  }

  $this: &;

  &[data-font-color="blue"] {
    #{$this}__box__title > * {
      color: $blue-dark !important;
    }
  }

  &__title > * {
    color: $white !important;
    line-height: $line-height-super-tight;
    @include responsive-font-size(((4.5rem), ($screen-sm-min, 7.3rem)));
  }

  &__box {
    &__title > * {
      color: $green !important;
      line-height: $line-height-super-tight;
      @include responsive-font-size(((3.4rem), ($screen-sm-min, 3.4rem)));
    }

    .fas {
        color: $green;
    }
  }

  &[data-next-cb="image_and_text_3"],
  &[data-next-cb="products_2"] {
    padding-bottom: 10rem;

    @media (min-width: $screen-lg-min) {
      padding-bottom: 25rem;
    }
  }
}