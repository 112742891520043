.cb--banner_1 {
  position: relative;
  background: rgb(0, 184, 241);
  background: linear-gradient(180deg, rgba(0, 184, 241, 1) 0%, rgba(91, 169, 155, 1) 26%, rgba(110, 162, 77, 1) 81%, rgba(175, 180, 39, 1) 100%);
  color: $white;
  padding-bottom: 4rem !important;
  overflow: hidden;

  @media (min-width: $screen-md-min) {
    padding-bottom: 15rem !important;
  }

  &__title > * {
    @extend .h2;
    @include responsive-font-size(((4.9rem), ($screen-sm-min, 5.6rem)));
    color: $white;
    padding-bottom: 2rem;
  }

  .button {
    padding: 0;
    width: 100%;
    justify-content: center;

    @media (min-width: $screen-sm-min) {
      width: auto;
      padding: 1.7rem 3.5rem;
    }
  }

  &__image {
    width: 100%;
    max-width: 40rem;
    height: auto;
    padding: 3rem 1rem 0 3rem;
    margin: 0 auto;

    @media (min-width: $screen-md-min) {
      position: absolute;
      bottom: 0;
      left: 50%;
      max-width: 55rem;
    }

    img {
      width: 100%;
      margin-bottom: -.7rem;
    }
  }
}