.cb--link_cards {
  position: relative;
  padding: 20rem 0;

  @media (min-width: $screen-lg-min) {
    padding: 10rem 0;
  }

  &[data-bg-color="green"] {
    background: linear-gradient(180deg, #6EA24D 0%, #B0B23C 74.4%, #B1B23C 100%);
  }

  &[data-prev-cb="banner_6"] {
    &::before {
      display: block;
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-bottom: 14%;
      z-index: 1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1918' height='278' fill='none'%3E%3Cmask id='a' width='2673' height='650' x='208' y='-57' maskUnits='userSpaceOnUse' style='mask-type:luminance'%3E%3Cpath fill='%23fff' d='M2869.85-56.796 208.854-11.559l10.268 604 2660.998-45.237-10.27-604Z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath fill='%236EA24D' d='M1910.7 9.324C281.375 37.023-1033.16 429.925-1025.39 886.897c4.28 4.328 5901.53-111.738 5900.31-100.306-7.77-456.972-1334.9-804.966-2964.22-777.267Z' opacity='.378'/%3E%3C/g%3E%3Cmask id='b' width='2267' height='693' x='265' y='-10' maskUnits='userSpaceOnUse' style='mask-type:luminance'%3E%3Cpath fill='%23fff' d='M2531.5 107.703 295.565-9.477l-30.145 575.21 2235.93 117.18 30.15-575.21Z'/%3E%3C/mask%3E%3Cg mask='url(%23b)'%3E%3Cpath fill='%23AFB427' d='M1570.21 107.192C-56.885 21.92-1395.29 322.734-1419.21 779.079c3.97 4.614 5894.23 297.481 5892.22 308.801 23.92-456.348-1275.71-895.416-2902.8-980.688Z' opacity='.763'/%3E%3C/g%3E%3Cmask id='c' width='1920' height='497' x='0' y='16' maskUnits='userSpaceOnUse' style='mask-type:luminance'%3E%3Cpath fill='%23fff' d='M1920 16.86H0v496h1920v-496Z'/%3E%3C/mask%3E%3Cg mask='url(%23c)'%3E%3Cpath fill='%236EA24D' d='M-29 66.66c-1629.33 0-2950.15 370.448-2950.15 827.419 4.2 4.4 5901.72-11.407 5900.3 0C2921.15 437.108 1600.32 66.66-29 66.66Z'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateY(-100%);
    }
  }

  &__card {
    display: block;
    background-color: $white;
    border-radius: 3rem;
    overflow: hidden;
    text-decoration: none;
    text-align: center;

    &:hover {
      transform: translateY(-1rem);
    }

    &__image {
      width: 8rem;
      height: 8rem;
      overflow: hidden;
      margin: 0 auto 2rem auto;

      @media (min-width: $screen-lg-min) {
        margin: 0 auto 4rem auto;
      }
    }

    &__title {
      font-size: 2.1rem;
      line-height: 1.4;
      color: $black;
      font-weight: $font-weight-medium;
    }

    &__button {
      background-color: #5BA99B;
      color: $white;
      font-weight: $font-weight-medium;
      font-size: 14px;
      text-align: center;
      padding: 2rem 0;
      text-transform: uppercase;

      @media (min-width: $screen-lg-min) {
        padding: 3rem 0;
      }
    }
  }
}