.tease-post {
  $this: &;

  &:hover, &:active {
    cursor: pointer;
  }

  &--is-green {
    &#{$this}--is-large {
      background-color: $green-lightest;

      @media (min-width: $screen-sm-min) {
        background-color: unset;
      }
    }

    #{$this}__title, #{$this}__date, #{$this}__link {
      color: $green;
    }
  }

  &--is-large {
    background-color: $gray-lightest;
    border-radius: 3rem;

    @media (min-width: $screen-sm-min) {
      background-color: unset;
      border-radius: unset;
    }

    #{$this}__image {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      @media (min-width: $screen-sm-min) {
        padding-bottom: 50%;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
      }
    }

    #{$this}__meta {
      padding: $bit-padding $bit-padding * 2;

      @media (min-width: $screen-sm-min) {
        padding: 0;
      }
    }
  }

  &__image {
    position: relative;
    padding-bottom: 70%;
    border-radius: 2rem;
    overflow: hidden;

    &__img {
      object-fit: cover;
      transition: all $transition-timing;
    }
  }

  &__title {
    @include responsive-font-size(((2.8rem), ($screen-sm-min, 3.1rem)));
    font-weight: $font-weight-demi;
  }

  &__date {
    color: $gray;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: $font-weight-demi;
  }

  &__excerpt {
    @include responsive-font-size(((1.6rem), ($screen-sm-min, 1.8rem)));

    p:last-child {
      padding-bottom: 0;
    }
  }

  &__link {
    color: $gray;
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: $font-weight-bold;
    font-size: 1.4rem;
  }
}