.featured-image {
  position: relative;
  padding-bottom: 80%;
  overflow: hidden;

  @include block-edge('top');

  &::before {
    top: 0; // Mixin is intended to be used to fall over previous block, not on this one though
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.597'%3E%3Cpath data-name='Subtraction 7' d='M1920 51.601c-75.275-7.263-152.56-13.739-229.708-19.25-77.929-5.566-157.7-10.289-237.112-14.037-80.308-3.79-162.3-6.681-243.7-8.592a10677.135 10677.135 0 0 0-498.957 0c-81.411 1.911-163.4 4.8-243.7 8.592-79.41 3.748-159.186 8.47-237.112 14.037C152.559 37.862 75.274 44.338.003 51.601V.001h1920v51.595Z' fill='%235ba99b'/%3E%3C/svg%3E");
  }

  @include block-edge('bottom');

  &::after {
    bottom: 0; // Mixin is intended to be used to fall over next block, not on this one though
    transform: rotate(180deg);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.597'%3E%3Cpath data-name='Subtraction 7' d='M1920 51.601c-75.275-7.263-152.56-13.739-229.708-19.25-77.929-5.566-157.7-10.289-237.112-14.037-80.308-3.79-162.3-6.681-243.7-8.592a10677.135 10677.135 0 0 0-498.957 0c-81.411 1.911-163.4 4.8-243.7 8.592-79.41 3.748-159.186 8.47-237.112 14.037C152.559 37.862 75.274 44.338.003 51.601V.001h1920v51.595Z' fill='%23fff'/%3E%3C/svg%3E");
  }

  @media (min-width: $screen-sm-min) {
    padding-bottom: 50%;
  }

  @media (min-width: $screen-md-min) {
    padding-bottom: 40%;
  }

  @media (min-width: $screen-lg-min) {
    padding-bottom: 35%;
  }

  &--edge-color-green::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='51.597'%3E%3Cpath data-name='Subtraction 7' d='M1920 51.601c-75.275-7.263-152.56-13.739-229.708-19.25-77.929-5.566-157.7-10.289-237.112-14.037-80.308-3.79-162.3-6.681-243.7-8.592a10677.135 10677.135 0 0 0-498.957 0c-81.411 1.911-163.4 4.8-243.7 8.592-79.41 3.748-159.186 8.47-237.112 14.037C152.559 37.862 75.274 44.338.003 51.601V.001h1920v51.595Z' fill='%236ea24d'/%3E%3C/svg%3E");
  }

  &__img {
    object-fit: cover;
  }

}