.cb--banner_2 {
  position: relative;
  padding-bottom: 7rem;

  $this: &;

  @media (min-width: $screen-md-min) {
    padding-bottom: 15rem;
  }

  @media (min-width: $screen-lg-min) {
    padding-bottom: 20rem;
  }

  & * {
    position: relative;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    padding-bottom: 14.5%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1922' height='279'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 434' transform='translate(-15304 -13307)' fill='%23fff' d='M0 0h1922v279H0z'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath data-name='Rectangle 201' fill='%236ea24d' stroke='%23707070' d='M0 0h2661v604H0z'/%3E%3C/clipPath%3E%3CclipPath id='c'%3E%3Cpath data-name='Rectangle 27' fill='%235ba99b' stroke='%23707070' d='M0 0h2239v576H0z'/%3E%3C/clipPath%3E%3CclipPath id='d'%3E%3Cpath data-name='Rectangle 27' fill='%23dfeeeb' stroke='%23707070' d='M0 0h1920v496H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 75' transform='translate(15304 13307)' clip-path='url(%23a)'%3E%3Cg data-name='Group 427'%3E%3Cg data-name='Mask Group 20' transform='translate(-15092.146 -13320.472)' opacity='.378' clip-path='url(%23b)'%3E%3Cpath data-name='Path 250' d='M1701 49.8c-1629.325 0-2950.153 370.448-2950.153 827.42 4.2 4.4 5901.723-11.408 5900.306 0C4651.153 420.248 3330.324 49.8 1701 49.8Z' fill='%236ea24d'/%3E%3C/g%3E%3Cg data-name='Mask Group 1' transform='rotate(3 246801.7 -293176.398)' opacity='.378' clip-path='url(%23c)'%3E%3Cpath data-name='Path 198' d='M1279 49.8c-1629.325 0-2950.153 370.449-2950.153 827.42 4.2 4.4 5901.723-11.407 5900.306 0C4229.153 420.249 2908.324 49.8 1279 49.8Z' fill='%235ba99b'/%3E%3C/g%3E%3C/g%3E%3Cg data-name='Group 426'%3E%3Cpath data-name='Rectangle 202' fill='%23dfeeeb' d='M-15304-12865.14h1920v759h-1920z'/%3E%3Cg data-name='Mask Group 1' clip-path='url(%23d)' transform='translate(-15304 -13284.14)'%3E%3Cpath data-name='Path 198' d='M-29 49.8c-1629.325 0-2950.153 370.448-2950.153 827.419 4.2 4.4 5901.724-11.407 5900.306 0C2921.153 420.248 1600.324 49.8-29 49.8Z' fill='%23dfeeeb'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    z-index: 1;
  }

  &[data-bg-color="green-lightest"] {
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1922' height='279'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 439' transform='translate(-15304 -13307)' fill='%23fff' d='M0 0h1922v279H0z'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath data-name='Rectangle 435' fill='%236ea24d' stroke='%23707070' d='M0 0h2661v604H0z'/%3E%3C/clipPath%3E%3CclipPath id='c'%3E%3Cpath data-name='Rectangle 436' fill='%235ba99b' stroke='%23707070' d='M0 0h2239v576H0z'/%3E%3C/clipPath%3E%3CclipPath id='d'%3E%3Cpath data-name='Rectangle 438' fill='%23f1f6ed' stroke='%23707070' d='M0 0h1920v496H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 79' transform='translate(15304 13307)' clip-path='url(%23a)'%3E%3Cg data-name='Group 428'%3E%3Cg data-name='Mask Group 76' transform='translate(-15092.146 -13320.472)' opacity='.378' clip-path='url(%23b)'%3E%3Cpath data-name='Path 306' d='M1701 49.8c-1629.325 0-2950.153 370.448-2950.153 827.42 4.2 4.4 5901.723-11.408 5900.306 0C4651.153 420.248 3330.324 49.8 1701 49.8Z' fill='%236ea24d'/%3E%3C/g%3E%3Cg data-name='Mask Group 77' transform='rotate(3 246801.7 -293176.398)' opacity='.378' clip-path='url(%23c)'%3E%3Cpath data-name='Path 307' d='M1279 49.8c-1629.325 0-2950.153 370.449-2950.153 827.42 4.2 4.4 5901.723-11.407 5900.306 0C4229.153 420.249 2908.324 49.8 1279 49.8Z' fill='%235ba99b'/%3E%3C/g%3E%3C/g%3E%3Cg data-name='Group 429'%3E%3Cpath data-name='Rectangle 437' fill='%23dfeeeb' d='M-15304-12865.14h1920v759h-1920z'/%3E%3Cg data-name='Mask Group 78' clip-path='url(%23d)' transform='translate(-15304 -13284.14)'%3E%3Cpath data-name='Path 308' d='M-29 49.8c-1629.325 0-2950.153 370.448-2950.153 827.419 4.2 4.4 5901.724-11.407 5900.306 0C2921.153 420.248 1600.324 49.8-29 49.8Z' fill='%23f1f6ed'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &[data-font-color="green"] {
    #{$this}__text-under-button {
      color: $green;
    }
  }

  i.fa-plus-circle {
    color: $gray;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__title > * {
    color: $white !important;
    line-height: $line-height-super-tight;
    @include responsive-font-size(((3.6rem), ($screen-sm-min, 7.3rem)));
  }

  &__box {
    border-radius: $border-radius-large;
    background-color: rgba($white, .9);
    box-shadow: 0 .3rem 1.2rem rgba($black, .1);
    z-index: 2;
  }

  &__box-title {
    color: $gray;
    @include responsive-font-size(((3rem), ($screen-sm-min, 3.4rem)));
  }

  &__rating {
    color: inherit;
    text-decoration: none;
    font-size: 1.4rem;

    i {
      color: $gray;
    }

    &:hover, &:active {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__button {
    @extend .button--gray;
  }

  &__text-under-button {
    color: $gray;
  }
}