.rounded-edge-shape {
  @include block-edge('top');

  &--up--white::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='50'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 436' transform='translate(0 474)' fill='%23FFF' stroke='%23707070' d='M0 0h1920v50H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 78' transform='translate(0 -474)' clip-path='url(%23a)'%3E%3Cpath data-name='Path 309' d='M960 473.8c1629.325 0 2950.153 370.448 2950.153 827.419-4.2 4.4-5901.724-11.407-5900.306 0C-1990.153 844.248-669.324 473.8 960 473.8Z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E");
  }

  &--up--green-lightest::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='50'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 436' transform='translate(0 474)' fill='%23F1F6ED' stroke='%23707070' d='M0 0h1920v50H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 78' transform='translate(0 -474)' clip-path='url(%23a)'%3E%3Cpath data-name='Path 309' d='M960 473.8c1629.325 0 2950.153 370.448 2950.153 827.419-4.2 4.4-5901.724-11.407-5900.306 0C-1990.153 844.248-669.324 473.8 960 473.8Z' fill='%23F1F6ED'/%3E%3C/g%3E%3C/svg%3E");
  }
}