//== Spacer classes
//====================================================
.margin-top {
    margin-top: $soda-gutter !important;
}

.margin-top-double {
    margin-top: $soda-gutter * 2 !important;
}

.margin-top-half {
    margin-top: $soda-gutter / 2 !important;
}

.margin-bottom {
    margin-bottom: $soda-gutter !important;
}

.margin-bottom-double {
    margin-bottom: $soda-gutter * 2 !important;
}

.margin-bottom-half {
    margin-bottom: $soda-gutter / 2 !important;
}

.margin-left {
    margin-left: $soda-gutter !important;
}

.margin-left-half {
    margin-left: $soda-gutter / 2 !important;
}

.margin-left-double {
    margin-left: $soda-gutter * 2 !important;
}

.margin-right {
    margin-right: $soda-gutter !important;
}

.margin-right-half {
    margin-right: $soda-gutter / 2 !important;
}

.margin-right-double {
    margin-right: $soda-gutter * 2 !important;
}

.margin {
    margin: $soda-gutter !important;
}

.margin-double {
    margin: $soda-gutter * 2 !important;
}

.margin-half {
    margin: $soda-gutter/2 !important;
}

.margin-none {
    margin: 0 !important;
}

.padding {
    padding: $soda-gutter !important;
}

.padding-double {
    padding: $soda-gutter * 2 !important;
}

.padding-half {
    padding: $soda-gutter/2 !important;
}

.padding-none {
    padding: 0 !important;
}

.padding-top {
    padding-top: $soda-gutter !important;
}

.padding-top-half {
    padding-top: $soda-gutter / 2 !important;
}

.padding-top-double {
    padding-top: $soda-gutter * 2 !important;
}

.padding-top-none {
    padding-top: 0 !important;
}

.padding-bottom {
    padding-bottom: $soda-gutter !important;
}

.padding-bottom-half {
    padding-bottom: $soda-gutter / 2 !important;
}

.padding-bottom-double {
    padding-bottom: $soda-gutter * 2 !important;
}

.padding-bottom-none {
    padding-bottom: 0 !important;
}

.padding-left-half {
    padding-left: $soda-gutter/2 !important;
}

.padding-left-double {
    padding-left: $soda-gutter*2 !important;
}

.padding-left {
    padding-left: $soda-gutter !important;
}

.padding-left-none {
    padding-left: 0 !important;
}

.padding-right-half {
    padding-right: $soda-gutter/2 !important;
}

.padding-right {
    padding-right: $soda-gutter !important;
}

.padding-right-double {
    padding-right: $soda-gutter*2 !important;
}

.padding-right-none {
    padding-right: 0 !important;
}
