.cb--banner_6 {
  position: relative;

  &[data-next-cb="link_cards"] {
    padding-bottom: 10rem;

    @media (min-width: $screen-lg-min) {
      padding-bottom: 25rem;
    }
  }

  form {
    label {
      color: $green;
    }

    input {
      border-color: $green;
    }

    button[type="submit"], button[type="button"] {
      @extend .button--green;
    }
  }

  &__title > * {
    color: $white !important;
    line-height: $line-height-super-tight;
    @include responsive-font-size(((4.5rem), ($screen-sm-min, 7.3rem)));
  }

}