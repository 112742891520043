@each $item in $text-sizes {
    $name: nth($item, 1);
    $size-xxs: nth($item, 2);
    $size-sm: nth($item, 3);

    .text--#{$name} {
        @include responsive-font-size((($size-xxs), ($screen-sm-min, $size-sm)));
        line-height: get-line-height($size-sm, $line-height-base-computed);
    }
}

.text--topline {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
}

.text--weight-normal {
    font-weight: $font-weight-normal;
}

.text--weight-demi {
    font-weight: $font-weight-demi;
}

.text--weight-semi-bold {
    font-weight: $font-weight-semi-bold;
}

.text--weight-bold {
    font-weight: $font-weight-bold;
}

.text--italic {
    font-style: italic;
}

.text--lineheight-tight {
    line-height: $line-height-tight;
}

.text--uppercase {
    text-transform: uppercase;
}

.text--no-text-transform {
    text-transform: unset;
}

.text--align-center {
    text-align: center;
}

.text--align-right {
    text-align: right;
}