.button, button, input[type='submit'] {
  display: inline-flex;
  align-items: center;
  min-height: 5rem;
  border-radius: 3rem;
  background: $gray;
  border: none;
  padding: 1.7rem 3.5rem;
  transition: $button-transition;
  font-weight: $font-weight-bold;
  color: $white;
  text-transform: uppercase;
  line-height: 1;
  font-size: 1.4rem;
  box-shadow: rgba($gray, .5) .1rem .3rem 0;

  &:hover, &:active {
    background: darken($gray, 4%);
    color: $white;
  }

  // Font Awesome icon
  i.fal, i.far, i.fas, i.fab {
    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 1rem;
    }
  }
}

.button {
  &--green-light {
    background-color: $green-light;
    box-shadow: rgba($green-light, .5) .1rem .3rem 0;

    &:hover, &:active {
      background: darken($green-light, 2%);
    }
  }

  &--gray {
    background-color: $gray;
    box-shadow: rgba($gray, .5) .1rem .3rem 0;

    &:hover, &:active {
      background: darken($gray, 4%);
    }
  }

  &--green {
    background-color: $green;
    box-shadow: rgba($green, .5) .1rem .3rem 0;

    &:hover, &:active {
      background: darken($green, 4%);
    }
  }

  &--blue {
    background-color: $blue;
    box-shadow: rgba($blue, .5) .1rem .3rem 0;

    &:hover, &:active {
      background: darken($blue, 4%);
    }
  }

  &--white {
    background-color: $white;
    box-shadow: rgba($white, .5) .1rem .3rem 0;
    color: $gray;

    &:hover, &:active {
      color: $gray;
      background: darken($white, 4%);
    }
  }

  &--white-with-green-text {
    @extend .button--white;
    color: $green;

    &:hover, &:active {
      color: $green;
    }
  }

  &--white-with-blue-text {
    @extend .button--white;
    color: $blue;

    &:hover, &:active {
      color: $blue;
    }
  }

  &--white-shadow {
    box-shadow: rgba($white, .5) .1rem .3rem 0;
  }

  &--link {
    padding: 0;
    min-height: unset;
    border-radius: 0;
    box-shadow: unset;
    border-bottom: 2px $gray solid;

    &, &:hover, &:active {
      background: transparent;
      color: $gray;
    }

    &:hover, &:active {
      color: darken($gray, 10%);
      border-color: darken($gray, 10%);
    }

    &--green {
      border-bottom: 2px $green solid;

      &, &:hover, &:active {
        color: $green;
      }

      &:hover, &:active {
        color: darken($green, 10%);
        border-color: darken($green, 10%);
      }
    }

    &--blue {
      border-bottom: 2px $blue solid;

      &, &:hover, &:active {
        color: $blue;
      }

      &:hover, &:active {
        color: darken($blue, 10%);
        border-color: darken($blue, 10%);
      }
    }

    &--white {
      border-bottom: 2px $white solid;

      &, &:hover, &:active {
        color: $white;
      }

      &:hover, &:active {
        color: darken($white, 10%);
        border-color: darken($white, 10%);
      }
    }
  }
}