.cb--banner_9 {
  position: relative;
  background-image: url("~/public/images/banner_9_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  padding-bottom: 4rem !important;
  overflow: hidden;

  &[data-next-cb="cards"] {
    padding-bottom: 10rem !important;

    @media (min-width: $screen-lg-min) {
      padding-bottom: 30rem !important;
    }
  }

  @media (min-width: $screen-md-min) {
    padding-bottom: 15rem !important;
  }

  &__title > * {
    @extend .h2;
    @include responsive-font-size(((4.9rem), ($screen-sm-min, 7.2rem)));
    color: $white;
    padding-bottom: 2rem;
    line-height: 1;
  }

  .button {
    padding: 0;
    width: 100%;
    justify-content: center;

    @media (min-width: $screen-sm-min) {
      width: auto;
      padding: 1.7rem 3.5rem;
    }
  }
}