.cb--steps_1 {
  &__title > * {
    @extend .h2;
  }

  &__slider {
    margin-right: - $bit-padding;
    margin-left: - $bit-padding;

    .swiper-slide {
      padding: 3.9rem $bit-padding $bit-padding;
      height: auto;
    }

    @media (min-width: $screen-sm-min) {
      margin-left: auto;
      margin-right: auto;
      max-width: 50rem;
    }

    @media (min-width: $screen-md-min) {
      display: flex;
      justify-content: space-between;
      max-width: unset;

      .swiper-wrapper > * {
        &:nth-child(2n) {
          padding-top: 8rem;
        }

        &:nth-child(3n) {
          padding-top: 4rem;
        }
      }
    }
  }

  &__step {
    position: relative;
    background: $white;
    border: 3px solid $white;
    border-radius: 3rem;
    text-align: center;
    box-shadow: rgba($black, .16) .3rem .3rem .6rem;
    height: 100%;

    @media (min-width: $screen-md-min) {
      height: auto;
    }

    &__image {
      padding-bottom: 4rem;
      border-top-left-radius: 3rem;
      border-top-right-radius: 3rem;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      color: $gray;
      font-weight: $font-weight-bold;
      @include responsive-font-size(((2.5rem), ($screen-sm-min, 2.8rem)));
    }

    &__text {
      padding: 0 2rem 2rem 2rem;

      @media (min-width: $screen-sm-min) {
        padding: 0 3rem 2rem 3rem;
      }
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -3.9rem;
      left: 50%;
      transform: translateX(-50%);
      background: $gray;
      color: $white;
      border: 2px solid $white;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      width: 7.8rem;
      height: 7.8rem;
      border-radius: 50%;
      box-shadow: rgba($black, .16) 0 .3rem .6rem;
      z-index: 1;
    }
  }
}