//== Responsive Background-images
//=========================================================
.responsivebackgroundimage {
    visibility: hidden;
    position: absolute;
    max-width: 100%;
    height: auto;
}

.responsivebackgroundimage__wrapper {
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
