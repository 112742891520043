.cb--offer {
  &__box {
    border-radius: 1rem;
    border: 1px solid $gray;
    @include responsive-font-size(((1.3rem), ($screen-sm-min, 1.6rem)));

    &__section {
      border-bottom: 1px solid $gray;
    }
  }
}