.content {
  overflow: hidden;

  img {
    border-radius: 2rem;
  }

  h1, .h1 {
    @include responsive-font-size(((2.6rem), ($screen-sm-min, 3rem)));
  }

  h2, .h2 {
    @include responsive-font-size(((2.4rem), ($screen-sm-min, 2.8rem)));
  }

  h3, .h3 {
    @include responsive-font-size(((2.2rem), ($screen-sm-min, 2.6rem)));
  }

  h4, .h4 {
    @include responsive-font-size(((2rem), ($screen-sm-min, 2.4rem)));
  }

  h5, .h5 {
    @include responsive-font-size(((1.8rem), ($screen-sm-min, 2rem)));
  }

  h6, .h6 {
    @include responsive-font-size(((1.6rem), ($screen-sm-min, 1.8rem)));
  }
}