$space-values: (
        0,
        5,
        7,
        10,
        15,
        20,
        30,
        40,
        50,
        75,
        100,
) !default;

$space-prefixes: (
        p : padding,
        pt : padding-top,
        pr : padding-right,
        pb : padding-bottom,
        pl : padding-left,
        m : margin,
        mt : margin-top,
        mr : margin-right,
        mb : margin-bottom,
        ml : margin-left,
) !default;

$breakpoints: $soda-breakpoints;

@mixin make-space($values, $prefixes, $breakpoints) {
  @each $breakpoint-name, $breakpoint-value in $breakpoints {
    @if ($breakpoint-value == 0) or ($breakpoint-value == null) {
      @each $attr-short, $attr-long in $prefixes {
        @each $value in $values {
          .#{$attr-short}-#{$value} {
            #{$attr-long}: #{$value / 10}#{if($value > 0, 'rem', '')} !important;
          }

          // Negative spacers
          @if (str-index($attr-long, 'margin')) and $value > 0 {
            .#{$attr-short}-n#{$value} {
              #{$attr-long}: - #{$value / 10}#{if($value > 0, 'rem', '')} !important;
            }
          }
        }
      }
    } @else {
      @media screen and (min-width: $breakpoint-value) {
        @each $attr-short, $attr-long in $prefixes {
          @each $value in $values {
            .#{$attr-short}-#{$breakpoint-name}-#{$value} {
              #{$attr-long}: #{$value / 10}#{if($value > 0, 'rem', '')} !important;
            }

            // Negative spacers
            @if (str-index($attr-long, 'margin')) and $value > 0 {
              .#{$attr-short}-#{$breakpoint-name}-n#{$value} {
                #{$attr-long}: - #{$value / 10}#{if($value > 0, 'rem', '')} !important;
              }
            }
          }
        }
      }
    }
  }
}

@include make-space($space-values, $space-prefixes, $breakpoints);
