.cb--energy_zero_form {

  form {
    label {
      color: $green;
    }

    input {
      border-color: $green;
    }

    button[type="submit"], button[type="button"] {
      @extend .button--green;
    }
  }

  &__rating {
    color: inherit;
    text-decoration: none;
    font-size: 1.4rem;

    i {
      color: $green !important;
    }

    &:hover, &:active {
      color: inherit;
      text-decoration: underline;
    }
  }
}