.scb--gallery {
  &__image {
    position: relative;
    border-radius: 2rem;
    overflow: hidden;
    padding-bottom: 80%;

    &__img {
      object-fit: cover;
    }
  }
}