.cb--reviews_slider {
  &__slider {
    margin-right: - $bit-padding;
    margin-left: - $bit-padding;

    .swiper-slide {
      padding: $bit-padding;
    }
  }

  &__review {
    text-align: center;
    font-size: 1.6rem;

    @media (min-width: $screen-md-min) {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
    }

    &__image {
      position: relative;
      border-top-left-radius: 3rem;
      border-top-right-radius: 3rem;
      box-shadow: rgba($black, .1) .3rem .3rem .6rem;
      overflow: hidden;
      margin: 0 3rem;

      @media (min-width: $screen-md-min) {
        width: 60%;
        border-radius: 3rem;
        margin-left: -5%;
        margin-top: 7%;
      }
    }

    &__card {
      position: relative;
      background-color: $white;
      border-radius: 3rem;
      box-shadow: rgba($black, .1) .3rem .3rem .6rem;
      z-index: 2;

      @media (min-width: $screen-md-min) {
        width: 60%;
        margin-right: -5%;
      }
    }
  }
}