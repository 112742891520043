.fancybox-slide {
  padding: $bit-padding;

  @media (min-width: $screen-sm-min) {
    padding: $bit-padding * 4 $bit-padding * 2;
  }
}

.fancybox__container {
  .carousel__button {
    &, &:hover, &:active {
      background-color: transparent;
      border-radius: 0;
    }
  }
}