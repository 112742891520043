.cb--banner_10 {

  &[data-next-cb="image_and_text_3"] {
    padding-bottom: 10rem;

    @media (min-width: $screen-lg-min) {
      padding-bottom: 25rem;
    }
  }
  
  &__title > * {
    color: $black !important;
    @include responsive-font-size(((3.6rem), ($screen-sm-min, 5.6rem)));
  }

  &__subtitle {
    @include responsive-font-size(((2.6rem), ($screen-sm-min, 4rem)));
    line-height: 1.2;
    color: $green;
  }

  &__price {
    @include responsive-font-size(((3.6rem), ($screen-sm-min, 5.6rem)));
    line-height: 1.2;
    color: $green;
    font-weight: $font-weight-bold;
  }
}