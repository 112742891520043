.quicklinks {
  position: fixed;
  top: 30%;
  right: -2px;
  z-index: $z-index-quicklinks;
  background-color: $white;
  box-shadow: rgba($black, .16) -.5rem .5rem .6rem;
  border: 2px solid $gray;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  height: 9rem;
  width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  a {
    display: block;
    color: $gray;
    text-align: center;
    line-height: 1;
  }

  &--blue {
    border-color: $blue;

    a {
      color: $blue;
    }
  }
}