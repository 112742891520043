.pre-footer {
  padding-bottom: 15rem;
  position: relative;
  background-color: $green-lightest;
  min-height: 20rem;

  $this: &;

  &--blue {
    background-color: $blue-light;

    #{$this}__shape::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='47.596'%3E%3Cpath data-name='Subtraction 7' d='M1920.001 47.595H.001V0c75.267 7.262 152.552 13.739 229.708 19.25 77.922 5.566 157.7 10.289 237.111 14.037 80.3 3.79 162.3 6.681 243.7 8.592a10677.05 10677.05 0 0 0 498.957 0c81.394-1.911 163.388-4.8 243.7-8.592 79.423-3.749 159.2-8.471 237.112-14.037 77.152-5.511 154.437-11.987 229.709-19.25v47.595Z' fill='%23EFF9FA'/%3E%3C/svg%3E");
    }
  }

  @media (min-width: $screen-md-min) {
    min-height: 30rem;
  }

  &__shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc((100% - 15rem) / 2);
    background: $white;

    &::before {
      content: " ";
      position: absolute;
      top: 100%;
      width: 100%;
      height: 5rem;
      background-repeat: no-repeat;
      background-position: left bottom;
      background-color: $white;
      background-size: auto 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='47.596'%3E%3Cpath data-name='Subtraction 7' d='M1920.001 47.595H.001V0c75.267 7.262 152.552 13.739 229.708 19.25 77.922 5.566 157.7 10.289 237.111 14.037 80.3 3.79 162.3 6.681 243.7 8.592a10677.05 10677.05 0 0 0 498.957 0c81.394-1.911 163.388-4.8 243.7-8.592 79.423-3.749 159.2-8.471 237.112-14.037 77.152-5.511 154.437-11.987 229.709-19.25v47.595Z' fill='%23f1f6ed'/%3E%3C/svg%3E");

      @media (min-width: $screen-md-min) {
        height: 8rem;
      }
    }

    &--large {
      height: 30%;
    }

    &--small {
      height: 5%;
    }
  }

  &__magazine, &__blog, &__dakcheck {
    position: relative;
    z-index: 2;
    background-color: $white;
    padding: 3rem;
    border-radius: 2rem;
    box-shadow: rgba($black, .1) 0 .3rem 1.2rem;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    & * {
      position: relative;
      z-index: 2;
    }

    @media (min-width: $screen-md-min) {
      padding: 4rem;
    }

    &__title {
      font-size: 3rem;
      font-weight: $font-weight-demi;
    }
  }

  &__magazine {
    &__title {
      color: $green;
    }

    &__text {
      p:last-child {
        max-width: 57%;
      }
    }

    &__image {
      position: absolute;
      top: 60%;
      right: -2rem;
      width: 50%;
      transform: translateY(-50%);

      @media (min-width: $screen-md-min) {
        width: 45%;
      }
    }
  }

  &__blog, &__dakcheck {
    &__title {
      color: $green-light;
      line-height: $line-height-tight;
    }

    &__text {
      max-width: 70%;

      @media (min-width: $screen-md-min) {
        max-width: 60%;
      }
    }

    &__image {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &::after {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        width: 90%;
        height: 100%;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
        z-index: 2;
      }

      &__img {
        object-fit: cover;
        object-position: right;
      }
    }
  }

  &__dakcheck {
    &__title {
      color: $gray;

      @media (min-width: $screen-md-min) {
        max-width: 50%;
      }
    }

    &__text {
      p:last-child {
        padding-bottom: 0;
      }
    }

    &__phone {
      a {
        text-decoration: none;
        font-size: 2.4rem;
        font-weight: $font-weight-bold;
        color: $gray;
      }
    }
  }

  &__share {
    position: relative;
    background-color: $white;
    box-shadow: rgba($black, .16) 0 .3rem 1.2rem;
    padding: 3rem 4rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 2;

    @media (min-width: $screen-sm-min) {
      padding: 6rem 8rem;
      flex-direction: row;
      justify-content: space-between;
    }

    p {
      font-size: 2.6rem;
      font-weight: $font-weight-demi;
      color: $gray;
    }

    a {
      display: block;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      line-height: 4rem;
      text-align: center;
      border: 2px solid $gray;
      color: $gray;
      transition: all $transition-timing;

      &:hover, &:active {
        background-color: $gray;
        color: $white;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &--green {
      p {
        color: $green;
      }

      a {
        border-color: $green;
        color: $green;

        &:hover, &:active {
          background-color: $green;
        }
      }
    }
  }

  &__project {
    &__image {
      position: relative;
      padding-bottom: 60%;
      border-radius: 2rem;
      overflow: hidden;

      &__img {
        object-fit: cover;
        border-radius: 2rem;
      }
    }
  }
}