.cb--banner_5 {
  position: relative;
  padding-bottom: 8rem;

  & + .cb {
    @media (min-width: $screen-md-min) {
      padding-top: 0;
    }
  }

  @media (min-width: $screen-sm-min) {
    padding-bottom: 15rem;
  }

  @media (min-width: $screen-md-min) {
    padding-bottom: 18rem;
  }

  @media (min-width: $screen-lg-min) {
    padding-bottom: 27rem;
  }

  & * {
    position: relative;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    padding-bottom: 14.5%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1917' height='278'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='none' d='M-5068-2058h1917v278h-1917z' data-name='Rectangle 475'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath fill='%23fff' stroke='%23707070' d='M0 0h2661v604H0z' data-name='Rectangle 201'/%3E%3C/clipPath%3E%3CclipPath id='c'%3E%3Cpath fill='%23fff' stroke='%23707070' d='M0 0h2239v576H0z' data-name='Rectangle 27'/%3E%3C/clipPath%3E%3CclipPath id='d'%3E%3Cpath fill='%23fff' stroke='%23707070' d='M0 0h1920v496H0z' data-name='Rectangle 27'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)' data-name='Mask Group 51' transform='translate(5068 2058)'%3E%3Cg data-name='Group 433'%3E%3Cg data-name='Group 201'%3E%3Cg clip-path='url(%23b)' data-name='Mask Group 20' opacity='.378' transform='translate(-4855.704 -2071.332)'%3E%3Cpath fill='%23fff' d='M1701 49.8c-1629.325 0-2950.153 370.448-2950.153 827.42 4.2 4.4 5901.723-11.408 5900.306 0C4651.153 420.248 3330.324 49.8 1701 49.8Z' data-name='Path 250'/%3E%3C/g%3E%3Cg clip-path='url(%23c)' data-name='Mask Group 1' opacity='.378' transform='rotate(3 37126.258 -92094.854)'%3E%3Cpath fill='%23fff' d='M1279 49.8c-1629.325 0-2950.153 370.449-2950.153 827.42 4.2 4.4 5901.723-11.407 5900.306 0C4229.153 420.249 2908.324 49.8 1279 49.8Z' data-name='Path 198'/%3E%3C/g%3E%3C/g%3E%3Cg data-name='Group 200'%3E%3Cpath fill='%23fff' d='M-5067.558-1620h1920v502h-1920z' data-name='Rectangle 202'/%3E%3Cg clip-path='url(%23d)' data-name='Mask Group 1' transform='translate(-5067.558 -2039)'%3E%3Cpath fill='%23fff' d='M-29 49.8c-1629.325 0-2950.153 370.448-2950.153 827.419 4.2 4.4 5901.724-11.407 5900.306 0C2921.153 420.248 1600.324 49.8-29 49.8Z' data-name='Path 198'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    z-index: 1;
  }

  &__title > * {
    @include responsive-font-size(((3.8rem), ($screen-sm-min, 5.6rem)));
  }

  &__subtitle {
    @include responsive-font-size(((2rem), ($screen-sm-min, 2.9rem)));
  }
}