.logo-slider {
  .swiper {
    padding-bottom: 0 !important;
  }

  &__image {
    position: relative;
    height: 6.3rem;
    width: 11rem;
    margin: 0 auto;

    &__img {
      object-fit: contain;
      max-height: 6.3rem;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    background-color: transparent;
    color: $gray;
    border-color: $gray;
    width: 2.2rem;
    height: 2.2rem;

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='11.5'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 55' fill='%235BA99B' d='M0 0h13v11.5H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Group 23' clip-path='url(%23a)'%3E%3Cpath data-name='Path 226' d='M0 6.784V4.713a.624.624 0 0 1 .621-.621h6.008V.621A.623.623 0 0 1 7.69.181l5.127 5.127a.623.623 0 0 1 0 .88L7.69 11.316a.621.621 0 0 1-1.061-.44v-3.47H.621A.624.624 0 0 1 0 6.784' fill='%235BA99B'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }
}