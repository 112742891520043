.modal {
  z-index: $z-index-modal;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: $bit-padding;
  backdrop-filter: blur(.5rem);
  background-color: rgba($white, .6);

  &__modal {
    background-color: $white;
    overflow: scroll;
    transition: all $transition-timing;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 3rem);
    max-width: 50rem;
    padding: $bit-padding * 2;
    box-shadow: 0 0 1rem rgba($black, .2);
    border-radius: 2rem;
  }

  &__close {
  }
}