flex-rates-component {
  border: 2px solid #DDDDDD;
  border-radius: 12px;
  --font-family: 'Ubuntu', sans-serif;

  /* variables */
  --primary-color: #aaaaaa;
  --background-color: transparant;
  --bar-color: #333333;

  /* arrow icon styles */
  --arrow--background-color: #aaaaaa;
  --arrow__container--height-width: 28px;

  /* custom styles */
  --font-size: 15px;
  --title--font-weight: 700;

  /* graph */
  --bar-average-color: #111111;

  /* footer */
  --footer--background-color: transparent;
  --footer-text-active-color: #777777;
  --footer--font-size: 16px;

  /* disclaimer styles */
  --disclaimer-color: #777777;
  --disclaimer--font-size: 11px;

  /* loader styles */
  --flex-loader-color: var(--text-color);

  /* tooltip */
  --icon--height-width: 26px;
  --information-button--color: #777777;
  --information-button--color-hover: var(--primary-color);

  /* legenda */
  --chart-title--font-size: 15px;
  --legenda-h1-size: 18px;
  --legenda-h1-color: #333333;
  --legenda-font-size: 16px;
  --legenda-max-width: 760px;
  --legenda-font-color: var(--text-color);
  --legenda-description-color: #333333;

  /* error */
  --error-color: #222222;
  --error-font-size: 12px;
}