.cb--team {
  &[data-font-color="gray"] {
    color: $gray;
  }

  &__slider {
    margin-right: - $bit-padding;
    margin-left: - $bit-padding;

    .swiper-slide {
      padding: 0 $bit-padding 5rem $bit-padding;

      @media (min-width: $screen-md-min) {
        padding: 0 $bit-padding;
      }
    }
  }

  &__person {
    text-align: center;

    &__image {
      width: 27rem;
      height: 27rem;
      border-radius: 50%;
      border: 1rem solid $white;
      box-shadow: rgba($black, .16) 0 .3rem .6rem;
      position: relative;
      overflow: hidden;
      margin: 0 auto;

      &__img {
        object-fit: cover;
      }
    }
  }
}