.google-review {
  font-family: "arial", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
  text-align: center;
  background-color: $white;
  box-shadow: rgba($black, .1) .3rem .3rem .6rem;
  border-radius: $border-radius-large;

  &__image {
    width: 4rem;
  }

  &__stars {
    svg {
      width: 2rem;
      height: auto;
    }
  }

  &__time-ago {
    color: #70757a;
    font-size: 1.4rem;
  }

  &__google-logo {
    color: #70757a;
    font-size: 1.2rem;

    svg {
      height: 3rem;
      width: auto;
    }
  }
}