.scb--cta_3 {
  &__wrapper {
    color: $white;
    background-color: $green;
    box-shadow: rgba($black, .1) 0 .3rem 1.2rem;
    border-radius: 3rem;
    font-weight: $font-weight-normal;

    p:last-child {
      padding-bottom: 0;
    }
  }

  &__title {
    @include responsive-font-size(((3rem), ($screen-sm-min, 3.8rem)));
    font-weight: $font-weight-demi;
    line-height: $line-height-tight;
  }
}