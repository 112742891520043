.cb--video_2 {
  position: relative;
  background-color: gray;

  &__placeholder {
    height: 30rem;

    @media (min-width: $screen-md-min) {
      height: 50rem;
    }

    @media (min-width: $screen-lg-min) {
      height: 60rem;
    }

    &__img {
      object-fit: cover;
    }
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7.5rem;
    height: 7.5rem;
    border: .5rem solid $white;
    border-radius: 50%;
    padding: 0;
    background-color: rgba($white, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;

    @media (min-width: $screen-md-min) {
      top: 55%;
      width: 10rem;
      height: 10rem;
      font-size: 4rem;
    }

    i.fas {
      margin: 0;
      padding-left: .5rem;
      color: $white;
      transition: all $transition-timing;
    }

    &:hover, &:active {
      background-color: $white;

      i.fas {
        color: $gray;
      }
    }
  }
}