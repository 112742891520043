.navbar-topbar {
  height: $navbar-topbar-height;
  background-color: $blue-light;
  color: $white;
  display: flex;
  justify-content: space-between;

  &__navigation {
    line-height: $navbar-topbar-height;
    font-size: 1.4rem;
    font-weight: $font-weight-normal;
    padding: 0 3rem;

    @media (min-width: $screen-lg-min) {
      padding: 0 7rem;
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: $black;
      margin-right: 2rem;
      transition: none;

      &.active {
        font-weight: bold;
      }

      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }

  &__flags {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 1rem;
    padding: 0 3rem;

    @media (min-width: $screen-lg-min) {
      padding: 0 1.5rem;
    }
  }

  &__flag {
    @apply tw-block tw-w-[21px] tw-h-[15px] tw-bg-cover tw-bg-no-repeat hover:tw-scale-110;

    &--nl {
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='20' viewBox='0 0 28 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.25' y='0.25' width='27.5' height='19.5' rx='1.75' fill='white' stroke='%23F5F5F5' stroke-width='0.5'/%3E%3Cmask id='mask0_142_119' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='28' height='20'%3E%3Crect x='0.25' y='0.25' width='27.5' height='19.5' rx='1.75' fill='white' stroke='white' stroke-width='0.5'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_142_119)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 6.66667H28V0H0V6.66667Z' fill='%23CA2B39'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 20H28V13.3333H0V20Z' fill='%232C56A2'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    &--za {
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='20' viewBox='0 0 28 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='28' height='20' rx='2' fill='%2306A86E'/%3E%3Cmask id='mask0_142_115' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='28' height='20'%3E%3Crect width='28' height='20' rx='2' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_142_115)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 2.66663L9.33333 9.99996L0 17.3333V2.66663Z' fill='%23FFBF2E'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M-1.33333 3L7.66667 10L-1.33333 17V3Z' fill='%23262626'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 8L2.66667 0H28V8H12Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.3333 6.66667L4.33333 0H28V6.66667H12.3333Z' fill='%23F44E46'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.66667 20H28V12H12L2.66667 20Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.33333 20H28V13.3334H12.3333L4.33333 20Z' fill='%23072CB4'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}