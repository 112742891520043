.cb--usps {
  $this: &;

  &[data-bg-color="gray-lightest"], &[data-bg-color="green-lightest"] {
    #{$this}__box {
      background-color: $white;
      border-radius: $border-radius-large;
      box-shadow: 0 .3rem 1.2rem rgba($black, .1);
      padding: 3rem 2rem;

      @media (min-width: $screen-sm-min) {
        padding: 5rem
      }

      @media (min-width: $screen-lg-min) {
        padding: 8rem 6rem 6rem 6rem;
      }
    }
  }
}