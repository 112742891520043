.cb--videos_slider {
  &__slider {
    margin-right: - $bit-padding;
    margin-left: - $bit-padding;

    .swiper-slide {
      padding: $bit-padding;
    }
  }

  &__video {
    position: relative;
    background-color: gray;
    overflow: hidden;
    @apply tw-rounded-3xl;

    &__placeholder {
      @apply tw-aspect-[4/3];

      //
      //height: 30rem;
      //
      //@media (min-width: $screen-md-min) {
      //  height: 50rem;
      //}
      //
      //@media (min-width: $screen-lg-min) {
      //  height: 60rem;
      //}

      &__img {
        object-fit: cover;
      }
    }


    &__play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7.5rem;
      height: 7.5rem;
      border: .5rem solid $white;
      border-radius: 50%;
      padding: 0;
      background-color: rgba($white, .5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem;

      @media (min-width: $screen-md-min) {
        //top: 55%;
        width: 10rem;
        height: 10rem;
        font-size: 4rem;
      }

      i.fas {
        margin: 0;
        padding-left: .5rem;
        color: $white;
        transition: all $transition-timing;
      }

      &:hover, &:active {
        background-color: $white;

        i.fas {
          color: $gray;
        }
      }
    }
  }

  //&__review {
  //  text-align: center;
  //  font-size: 1.6rem;
  //
  //  @media (min-width: $screen-md-min) {
  //    display: flex;
  //    flex-direction: row-reverse;
  //    align-items: flex-start;
  //  }
  //
  //  &__image {
  //    position: relative;
  //    border-top-left-radius: 3rem;
  //    border-top-right-radius: 3rem;
  //    box-shadow: rgba($black, .1) .3rem .3rem .6rem;
  //    overflow: hidden;
  //    margin: 0 3rem;
  //
  //    @media (min-width: $screen-md-min) {
  //      width: 60%;
  //      border-radius: 3rem;
  //      margin-left: -5%;
  //      margin-top: 7%;
  //    }
  //  }
  //
  //  &__card {
  //    position: relative;
  //    background-color: $white;
  //    border-radius: 3rem;
  //    box-shadow: rgba($black, .1) .3rem .3rem .6rem;
  //    z-index: 2;
  //
  //    @media (min-width: $screen-md-min) {
  //      width: 60%;
  //      margin-right: -5%;
  //    }
  //  }
  //}
}