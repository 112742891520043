.scb--columns {
  &__image {
    position: relative;
    padding-bottom: 100%;
    border-radius: 2rem;
    overflow: hidden;

    &__img {
        object-fit: cover;
    }
  }
}