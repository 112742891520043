.energy-prices-chart {
  &__buttons-wrapper {
    @media (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__button-list {
    margin: 0;
    list-style: none;
    display: flex;
    margin: 0 -.3rem;

    &--energy-type {
      li {
        width: 50%;

        @media (min-width: $screen-sm-min) {
          width: auto;
        }
      }
    }

    &--time-period {
      li {
        width: 25%;

        @media (min-width: $screen-sm-min) {
          width: auto;
        }
      }
    }

    li {
      padding: 0 .3rem .3rem .3rem;

      input {
        display: none;

        &:checked + label {
          background-color: $blue-light;
        }
      }

      label {
        text-align: center;
        display: block;
        padding: .8rem;
        background-color: $white;
        border-radius: 5rem;
        color: $blue;
        border: 1px solid $blue;
        font-size: $font-size-small;
        transition: all $transition-timing;

        @media (min-width: $screen-sm-min) {
          padding: .8rem 2rem;
        }

        &:hover, &:active {
          cursor: pointer;
        }
      }
    }
  }

  &__arrow {
    background: $white;
    box-shadow: none;
    border: 1px solid $blue;
    padding: 0;
    min-height: unset;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    font-size: 1.2rem;

    i.fas {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &:hover, &:active {
      background-color: $blue-light;
    }
  }

  &__spinner {
    background-color: rgba($blue-light, .7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue;
    font-size: 3rem;
  }
}