.template-projects {
  text-align: center;

  &__slider-block {
    background-color: $green-lightest;

    .swiper-slide {
      &:nth-child(2) {
        h2, .quote__text {
          color: $green;
        }

        .quote__shape {
          background-color: rgba($green, .2);
        }
      }

      &:nth-child(3) {
        h2, .quote__text {
          color: $green-light;
        }

        .quote__shape {
          background-color: rgba($green-light, .2);
        }
      }
    }
  }

  &__quote-block {
    position: relative;
    overflow: hidden;
    background-color: $green-lightest;
    padding-bottom: $bit-padding;

    &::before {
      z-index: 1;
      position: absolute;
      bottom: -1px;
      left: 0;
      content: " ";
      height: 8rem;
      width: 200%;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: left bottom;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1920' height='50'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 436' transform='translate(0 474)' fill='%23FFF' stroke='%23707070' d='M0 0h1920v50H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Mask Group 78' transform='translate(0 -474)' clip-path='url(%23a)'%3E%3Cpath data-name='Path 309' d='M960 473.8c1629.325 0 2950.153 370.448 2950.153 827.419-4.2 4.4-5901.724-11.407-5900.306 0C-1990.153 844.248-669.324 473.8 960 473.8Z' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E");

      @media (min-width: $screen-md-min) {
        height: 7rem;
        width: 150%;
      }
    }

    &__quote {
      position: relative;
      background-color: $white;
      padding: 3rem 3rem 8rem 3rem;
      border-radius: 2rem;
      box-shadow: rgba($black, .1) 0 .3rem 1.2rem;
      overflow: hidden;
      height: 100%;
      z-index: 2;

      @media (min-width: $screen-sm-min) {
        padding: 8rem 6rem;
      }

      @media (min-width: $screen-md-min) {
        padding: 10rem 8rem;
      }
    }

    &__text {
      position: relative;
      z-index: 2;
      max-width: 70%;
      @include responsive-font-size(((2.2rem), ($screen-sm-min, 3.2rem)));
      font-weight: $font-weight-demi;
      font-style: italic;
      color: $gray;
      line-height: 1.3;

      @media (min-width: $screen-sm-min) {
        max-width: 60%;
      }
    }

    &__image {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;

      @media (min-width: $screen-sm-min) {
        width: 70%;
      }

      &::after {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        width: 90%;
        height: 100%;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
        z-index: 2;
      }

      &__img {
        object-fit: cover;
        object-position: right;
      }
    }
  }
}