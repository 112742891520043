.date {
.react-datepicker {
  font-size: 1.4rem;

  &__day {
    width: 2.5rem;
    line-height: 2.5rem;
  }

  &__current-month {
    font-size: inherit;
  }

  &__header {
    &__dropdown {
      margin: .5rem 0;
    }
  }

  &__day-names {
    display: none;
  }

  button.react-datepicker__navigation {
    min-height: unset;
    border-radius: unset;
    box-shadow: unset;
    font-size: unset;
  }

  select {
    padding: .3rem;
    min-height: unset;
    min-width: unset;
    font-size: unset;
  }
}
}